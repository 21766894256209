<template>
  <RevTextList variant="ordered">
    <template #default>
      <RevTextListItem
        v-for="{ title, paragraph, id } in instructions"
        :key="id"
        class="flex"
      >
        <div>
          <p class="text-action-default-hi body-1">{{ title }}</p>
          <p class="body-2 mt-1">{{ paragraph }}</p>
        </div>
      </RevTextListItem>
    </template>
  </RevTextList>
</template>

<script setup lang="ts">
import { type ComputedRef, computed } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'

import translations from './Instructions.translations'

interface InstructionsProps {
  price?: string
  model?: string
  daysLeft?: number
  shippingMode?: string
}

interface Instruction {
  id: string
  paragraph: string
  title: string
}

const props = defineProps<InstructionsProps>()
const i18n = useI18n()

const instructions: ComputedRef<Array<Instruction>> = computed(() => {
  const isKit = props.shippingMode === BUYBACK_SHIPPING_MODE.KIT

  const firstBlock = isKit
    ? {
        id: 'shipMode',
        paragraph: i18n(translations.instructionReceiveKitTitle),
        title: i18n(translations.instructionReceiveKitParagraph),
      }
    : {
        id: 'shipMode',
        paragraph: i18n(translations.instructionPrintPrepaidLabelParagraph),
        title: i18n(translations.instructionPrintPrepaidLabelTitle),
      }

  return [
    firstBlock,
    {
      id: 'delivery',
      title: i18n(translations.instructionShipItemTitle, {
        model: props.model,
        days: props.daysLeft,
      }),
      paragraph: i18n(translations.instructionShipItemParagraph),
    },
    {
      id: 'payment',
      title: i18n(translations.instructionPaymentTitle),
      paragraph: i18n(translations.instructionPaymentParagraph, {
        amount: props.price,
      }),
    },
  ]
})
</script>
